import { computed } from "vue";
import { jwtDecode } from "jwt-decode";
import store from "@/store";
import router from "@/router";

const useAuth = () => {
  const getCurrentUser = computed(() => store.getters["auth/getCurrentUser"]);

  const userName = computed(() =>
    getCurrentUser.value?.name
      ? getCurrentUser.value?.name.charAt(0).toUpperCase() +
        getCurrentUser.value?.name.slice(1).toLowerCase()
      : getCurrentUser.value?.uid
  );

  const setFedIdUser = (idToken) => {
    const decodedFedIdUser = jwtDecode(idToken);
    store.commit("auth/setFedIdUser", decodedFedIdUser);
  };

  const setCurrentUser = async (userToken) => {
    return await store.dispatch("auth/setCurrentUser", userToken);
  };

  const checkAuthStatus = async (payload) => {
    return await store.dispatch("auth/checkAuthentication", payload);
  };

  const logout = async (payload) => {
    await store.dispatch("auth/logout", payload).then(() => {
      router.push("/logout");
      localStorage.clear();
    });
  };

  const setExpiredSession = () => {
    store.commit("auth/sessionExpired");
  };

  return {
    setFedIdUser,
    checkAuthStatus,
    setCurrentUser,
    logout,
    setExpiredSession,

    getCurrentUser,
    userName,
    getLoadingState: computed(() => store.getters["auth/getLoadingState"]),
    authToken: computed(() => store.getters["auth/authToken"]),
    getUserId: computed(() => store.getters["auth/getUserId"]),
    getFedIdUser: computed(() => store.getters["auth/getFedIdUser"]),
    getUserStatus: computed(() => store.getters["auth/getUserStatus"]),
    getSessionStatus: computed(() => store.getters["auth/sessionStatus"]),
    getRole: computed(() => store.getters["auth/getRole"]),
    getLoginError: computed(() => store.getters["auth/getLoginError"]),
    getErrorMessage: computed(() => store.getters["auth/getErrorMessage"]),
  };
};

export default useAuth;
